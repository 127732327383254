import { AbstractSurfaceCalculator } from "./AbstractSurfaceCalculator";
import { HouseTypeHelper } from "../../../../util/HouseTypeHelper";
import { RoofType } from "../../../../constants/RoofType";
import { Directions } from "../../../../constants/Directions";

export class BlockSurfaceCalculator extends AbstractSurfaceCalculator {
  doCalculation = (surfaceData, type, roofData) => {
    const strop = 0.3;
    const floorCount = roofData.attic
      ? surfaceData.floorCount - 1
      : surfaceData.floorCount;
    let roofWallWE = 0;
    let roofWallS = 0;
    const totalSurface = surfaceData.totalSurface * 1.12;

    surfaceData.floorArea = totalSurface / surfaceData.floorCount;
    surfaceData.floorSurface = 0;
    surfaceData.atticSurface = surfaceData.floorArea;

    let floorSurfaceData = this.calcFloorSurfaceData(
      surfaceData.floorArea,
      type
    );
    const roofHeight = this.calcRoofHeight(floorSurfaceData.b, roofData.angle);
    let wallLength = 0;
    if (HouseTypeHelper.hasOnlyOneOuterWall(type)) {
      wallLength = floorSurfaceData.a;
    } else if (HouseTypeHelper.isCenterThinBlock(type)) {
      wallLength = 2 * floorSurfaceData.a;
    } else if (HouseTypeHelper.hasTwoOuterWalls(type)) {
      wallLength = floorSurfaceData.a + floorSurfaceData.b;
    } else if (HouseTypeHelper.hasThreeOuterWalls(type)) {
      wallLength = 2 * floorSurfaceData.a + floorSurfaceData.b;
    }

    surfaceData.wallSurface =
      wallLength * surfaceData.floorHeight * floorCount +
      wallLength * strop * (surfaceData.floorCount - 1);
    surfaceData.windowsSurface = 0.15 * totalSurface;

    if (HouseTypeHelper.hasOnlyWEWindows(type)) {
      surfaceData.windowsSurfaceWE = surfaceData.windowsSurface;
      surfaceData.windowsSurfaceS = 0;
      surfaceData.windowsSurfaceN = 0;
    } else if (HouseTypeHelper.isBlockWithOuterWall(type)) {
      surfaceData.windowsSurfaceS = 0.35 * surfaceData.windowsSurface;
      surfaceData.windowsSurfaceN = 0;
      surfaceData.windowsSurfaceWE = 0.65 * surfaceData.windowsSurface;
    } else if (HouseTypeHelper.isDoubleBlockWithOuterWall(type)) {
      surfaceData.windowsSurfaceS = 0.25 * surfaceData.windowsSurface;
      surfaceData.windowsSurfaceN = 0;
      surfaceData.windowsSurfaceWE = 0.75 * surfaceData.windowsSurface;
    } else if (HouseTypeHelper.hasOnlyOneOuterWall(type)) {
      surfaceData.windowsSurfaceS = 0;
      surfaceData.windowsSurfaceN = 0;
      surfaceData.windowsSurfaceWE = surfaceData.windowsSurface;
    } else {
      surfaceData.windowsSurfaceS = surfaceData.windowsSurface * 0.45;
      surfaceData.windowsSurfaceN = surfaceData.windowsSurface * 0.15;
      surfaceData.windowsSurfaceWE = surfaceData.windowsSurface * 0.4;
    }

    surfaceData.roofSurface = 0;
    surfaceData.floorSurfaceC = 0;
    surfaceData.floorSurfaceR = 0;

    if (HouseTypeHelper.isGroundType(type)) {
      surfaceData.floorSurface = surfaceData.floorArea;
      surfaceData.floorSurfaceC =
        (Math.sqrt(surfaceData.floorSurface) - 2) *
        (Math.sqrt(surfaceData.floorSurface) - 2);
      surfaceData.floorSurfaceR =
        surfaceData.floorSurface - surfaceData.floorSurfaceC;
    }
    if (HouseTypeHelper.isSkyType(type)) {
      surfaceData.roofSurface = surfaceData.floorArea;

      if (roofData.type === RoofType.FLAT) {
        surfaceData.atticWallSurface = 0;
        surfaceData.roofWallSurface = 0;
        surfaceData.roofSurface = surfaceData.floorArea;
        surfaceData.insulatedAtticWall = 0;
        surfaceData.nonInsulatedAtticWall = 0;
      } else if (roofData.type === RoofType.COMPLICATED) {
        surfaceData.roofSurface = Math.abs(
          (surfaceData.floorArea /
            Math.cos(this.degreesToRadians(roofData.angle))) *
            1.05 *
            1.2
        );
        surfaceData.atticWallSurface = roofData.attic
          ? wallLength * roofData.kneewall
          : 0;
        surfaceData.insulatedAtticWall = surfaceData.atticWallSurface;
        surfaceData.nonInsulatedAtticWall = 0;
        surfaceData.roofWallSurface = 0;
      } else {
        surfaceData.roofSurface = Math.abs(
          (surfaceData.floorArea /
            Math.cos(this.degreesToRadians(roofData.angle))) *
            1.05
        );
        surfaceData.atticWallSurface = roofData.attic
          ? wallLength * roofData.kneewall
          : 0;
        surfaceData.insulatedAtticWall = surfaceData.atticWallSurface;
        surfaceData.nonInsulatedAtticWall = 0;

        if (roofData.type === RoofType.PITCHED) {
          if (HouseTypeHelper.isDoubleBlock(type)) {
            surfaceData.roofWallSurface =
              ((floorSurfaceData.a / 2 + floorSurfaceData.b) * roofHeight) / 2;
          } else {
            surfaceData.roofWallSurface =
              (floorSurfaceData.a + floorSurfaceData.b) * roofHeight;
          }
        } else if (roofData.type === RoofType.GABLE) {
          surfaceData.roofWallSurface = (floorSurfaceData.a * roofHeight) / 2;
          if (HouseTypeHelper.isDoubleBlock(type)) {
            surfaceData.roofWallSurface = surfaceData.roofWallSurface / 2;
          }
        }
      }
    }

    surfaceData.wallSurface = Math.max(
      surfaceData.wallSurface - surfaceData.windowsSurface,
      0
    );
    surfaceData.insulatedWallSurface =
      surfaceData.wallSurface +
      surfaceData.insulatedAtticWall +
      surfaceData.roofWallSurface;
    surfaceData.nonInsulatedWallSurface = 0;
    surfaceData.wallsCount = 1;
    surfaceData.wallsData = [
      {
        width:
          floorSurfaceData.a * surfaceData.floorHeight * floorCount +
          floorSurfaceData.a * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.a * roofData.kneewall : 0) +
          roofWallWE / 2,
        direction: Directions.EAST,
        window: HouseTypeHelper.isDoubleBlock(type)
          ? surfaceData.windowsSurfaceWE
          : surfaceData.windowsSurfaceWE / 2,
        insulated: true,
      },
    ];
    if (HouseTypeHelper.isThinBlock(type)) {
      surfaceData.wallsCount = 2;
      surfaceData.wallsData.push({
        width:
          floorSurfaceData.a * surfaceData.floorHeight * floorCount +
          floorSurfaceData.a * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.a * roofData.kneewall : 0) +
          roofWallWE / 2,
        direction: Directions.WEST,
        window: surfaceData.windowsSurfaceWE / 2,
        insulated: true,
      });
    }

    if (
      HouseTypeHelper.isBlockWithOuterWall(type) ||
      HouseTypeHelper.isDoubleBlockWithOuterWall(type)
    ) {
      surfaceData.wallsCount = 3;
      surfaceData.wallsData.push({
        width:
          floorSurfaceData.b * surfaceData.floorHeight * floorCount +
          floorSurfaceData.b * strop * (surfaceData.floorCount - 1) +
          (roofData.attic ? floorSurfaceData.b * roofData.kneewall : 0) +
          roofWallS,
        direction: Directions.SOUTH,
        window: surfaceData.windowsSurfaceS,
        insulated: true,
      });
    }

    return surfaceData;
  };

  doFinalCalculation(surfaceData) {
    surfaceData = super.doFinalCalculation(surfaceData);
    surfaceData.floorSurfaceR = 0;
    return surfaceData;
  }

  supportsHouseType = type => HouseTypeHelper.isBlockType(type);
}
