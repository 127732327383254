import React from "react";
import { connect } from "react-redux";
import {
  updateCosts,
  updateVentilation,
  updateVentilationById,
} from "../../redux/actions";
import { getHouse, getVentilation } from "../../redux/selectors";

import { FormattedMessage, injectIntl } from "react-intl";
import { HouseTypeHelper } from "../../util/HouseTypeHelper";

import { DropDownList } from "../Helpers/DropDownList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popup } from "../Helpers/Popup";
import { VentilationMethod } from "../../config/dictionary/model/VentilationMethod";
import cx from "classnames";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import iconSet from "./../../selection.json";
import IcomoonReact from "icomoon-react";
import { HeatMaster } from "../HeatMaster";
import { OptimizerResultsState } from "../../core/optimizer/OptimizerResultsState";
import { AirTightness } from "../../config/dictionary/model/AirTightness";
import { RecuperatorLocation } from "../../constants/combo/RecuperatorLocation";
import NumericInput from "react-numeric-input";
import { FormatNumericInput } from "../Helpers/FormatNumericInput";
import { ThermodermVentilation } from "../Helpers/ThermodermVentilation";

class VentilationBaloon extends HeatMaster {
  constructor(props) {
    super(props);

    this.state = {
      showGWC: false,
      showCo2Sensor: false,
      showBaloon: window.innerWidth >= 1024,
      show: true,
    };
    this.configurationRead(this.props.businessConfig);
  }

  handleBaloonClick = () => {
    this.setState({ showBaloon: !this.state.showBaloon });
  };

  airTightnessList;
  ventilationMethodList;

  locationList = [
    {
      name: "hm.label.recuperator.location.default",
      data: {
        id: RecuperatorLocation.DEFAULT,
      },
    },
    {
      name: "hm.label.recuperator.location.individual",
      data: {
        id: RecuperatorLocation.INDIVIDUAL,
        multi: true,
      },
    },
    {
      name: "hm.label.recuperator.location.aggregated",
      data: {
        id: RecuperatorLocation.AGGREGATED,
        multi: true,
      },
    },
  ];

  configurationRead = businessConfiguration => {
    this.ventilationMethodList = this.toComboObjectList(
      businessConfiguration.dictionaryConfig.ventilationTypeConfiguration
        .ventilationTypes
    );
    this.airTightnessList = this.toComboObjectList(
      businessConfiguration.dictionaryConfig.tightnessConfiguration
        .tightnessItems
    );
  };

  ventilationChanged = e => {
    this.props
      .dispatch(
        updateVentilation({
          ventilation: e,
          co2Sensor:
            this.props.ventilation.ventilation === "hm.ventilation-type.natural"
              ? false
              : this.props.ventilation.co2Sensor,
          gwcSet: this.props.ventilation.gwcSet,
          isMechanical:
            VentilationMethod.getVentilationMethodById(e).type ===
            VentilationMethod.MECHANICAL,
          humidification:
            (this.props.ventilation.airTightness !==
              "hm.tightness.tight_recup" &&
              this.props.ventilation.airTightness !==
                "hm.tightness.standard_passive") ||
            e === "hm.ventilation-type.natural"
              ? false
              : this.props.ventilation.humidification,
          recuperator: VentilationMethod.getVentilationMethodById(e)
            .recuperator,
          patency:
            this.props.house.buildingType === "new" ||
            AirTightness.getAirTightnessById(
              this.props.ventilation.airTightness
            ).ventNatural === false
              ? 100
              : this.props.ventilation.patency,
        })
      )
      .then(() => {
        this.props
          .dispatch(
            updateCosts({
              ventilation_work_changed: false,
              ventilation_material_changed: false,
            })
          )
          .then(() => {
            this.props.writeCache();
          });
      });
  };

  airTightnessChanged = (e, ifChangeN50 = true) => {
    this.props
      .dispatch(
        updateVentilation({
          ventilation:
            VentilationMethod.getVentilationMethodById(
              this.props.ventilation.ventilation
            ).recuperator &&
            (e === "hm.tightness.standard_passive" ||
              e === "hm.tightness.tight_recup")
              ? this.props.ventilation.ventilation
              : e === "hm.tightness.tight_recup" ||
                e === "hm.tightness.standard_passive"
              ? "hm.recuperator-type.cross"
              : e !== "hm.tightness.tight_recup" &&
                e !== "hm.tightness.standard_passive"
              ? "hm.ventilation-type.natural"
              : this.props.ventilation.ventilation,
          airTightness: e,
          n50: ifChangeN50
            ? this.airTightnessList.find(item => item.data.id === e).data.value
            : this.props.ventilation.n50,
          co2Sensor:
            e === "hm.ventilation-type.naturale"
              ? false
              : this.props.ventilation.co2Sensor,
          gwcSet: this.props.ventilation.gwcSet,
          isMechanical:
            e === "hm.tightness.tight_recup" ||
            e === "hm.tightness.standard_passive"
              ? false
              : e !== "hm.tightness.tight_recup" &&
                e !== "hm.tightness.standard_passive"
              ? false
              : this.props.ventilation.isMechanical,
          humidification:
            e !== "hm.tightness.tight_recup" &&
            e !== "hm.tightness.standard_passive"
              ? false
              : this.props.ventilation.humidification,
          recuperator:
            e === "hm.tightness.tight_recup" ||
            e === "hm.tightness.standard_passive"
              ? true
              : e !== "hm.tightness.tight_recup" &&
                e !== "hm.tightness.standard_passive"
              ? false
              : this.props.ventilation.isMechanical,
          patency:
            this.props.house.buildingType === "new" ||
            AirTightness.getAirTightnessById(
              this.props.ventilation.airTightness
            ).ventNatural === false
              ? 100
              : this.props.ventilation.patency,
        })
      )
      .then(() => {
        this.props
          .dispatch(
            updateCosts({
              tightness_changed: false,
            })
          )
          .then(() => {
            this.props.writeCache();
          });
      });
  };

  co2SensorChanged = e => {
    this.props
      .dispatch(
        updateVentilationById({
          id: "co2Sensor",
          value: e,
        })
      )
      .then(() => {
        this.props
          .dispatch(
            updateCosts({
              co2Sensor_changed: false,
            })
          )
          .then(() => {
            this.props.writeCache();
          });
      });
  };

  gwcChanged = e => {
    this.props
      .dispatch(
        updateVentilation({
          ventilation: this.props.ventilation.ventilation,
          airTightness: this.props.ventilation.airTightness,
          co2Sensor: this.props.ventilation.co2Sensor,
          gwcSet: e,
          isMechanical: this.props.ventilation.isMechanical,
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  ventilationPatencyChanged = e => {
    this.props
      .dispatch(
        updateVentilation({
          patency: e,
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  humidificationChanged = e => {
    this.props
      .dispatch(
        updateVentilationById({
          id: "humidification",
          value: e,
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  locationChanged = e => {
    this.props
      .dispatch(
        updateVentilation({
          recuperatorLocation: parseInt(e),
        })
      )
      .then(() => {
        this.props.writeCache();
      });
  };

  n50Change = e => {
    this.props
      .dispatch(
        updateVentilation({
          n50: e,
        })
      )
      .then(() => {
        const items = this.airTightnessList.slice(0);
        items.reverse();
        const item = items.find(item => item.data.value >= e);
        this.airTightnessChanged(item.data.id, false);
      });
  };

  render() {
    let worse;
    let better;
    if (this.props.invalidDataOptimizer) {
      worse = this.props.dataOptimizer.map(
        item => item.state === OptimizerResultsState.WORSE
      );
      worse = worse.includes(true);
      better = this.props.dataOptimizer.map(
        item => item.state === OptimizerResultsState.BETTER
      );
      better = better.includes(true);
    }
    const houseType = this.props.house.houseType;
    const isMulti = HouseTypeHelper.isMultiFamilyType(houseType);

    let locationList = this.locationList.filter(
      item => item.data.default === true
    );
    let ventilationMethodList = this.ventilationMethodList;

    if (
      AirTightness.getAirTightnessById(this.props.ventilation.airTightness)
        .ventNatural === false
    ) {
      if (
        AirTightness.getAirTightnessById(this.props.ventilation.airTightness)
          .value < 1
      ) {
        ventilationMethodList = this.ventilationMethodList.filter(
          item => item.data.recuperator === true
        );
      }
      if (isMulti) {
        locationList = this.locationList.filter(
          item => item.data.multi === true
        );

        if (
          this.props.ventilation.recuperatorLocation ===
          RecuperatorLocation.INDIVIDUAL
        ) {
          ventilationMethodList = this.ventilationMethodList.filter(
            item => item.data.multiHouse === true
          );
        } else if (
          this.props.ventilation.recuperatorLocation ===
          RecuperatorLocation.AGGREGATED
        ) {
          ventilationMethodList = this.ventilationMethodList.filter(
            item => item.data.multiHouseAggregated === true
          );
        }
      }
    } else if (
      AirTightness.getAirTightnessById(this.props.ventilation.airTightness)
        .ventNatural === true
    ) {
      ventilationMethodList = this.ventilationMethodList.filter(
        item => item.data.recuperator === false
      );
    }

    const ventilationMethod = VentilationMethod.getVentilationMethodById(
      this.props.ventilation.ventilation
    );
    const gravitational =
      ventilationMethod.id === "hm.ventilation-type.natural";
    const needDefrosting = !ventilationMethod.defrosting;
    const needHumidification = !ventilationMethod.humidification;
    return (
      <Card
        className={cx(
          "baloon ventilation-baloon popover bs-popover-left d-flex align-items-center",
          { hidden: !this.state.showBaloon },
          { warning: this.props.invalidDataOptimizer && worse },
          { success: this.props.invalidDataOptimizer && better && !worse }
        )}
      >
        <FormattedMessage id="hm.label.ventilation" defaultMessage="">
          {message => (
            <span
              title={message}
              className={"baloon-icon"}
              onClick={() => this.handleBaloonClick()}
            >
              <IcomoonReact
                iconSet={iconSet}
                size={22}
                icon="ventilation_icon-color"
              />
            </span>
          )}
        </FormattedMessage>

        <div className="arrow m-0" />
        <div className={"popover-body"}>
          <div className={"content"}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey="3"
              className={"d-flex align-items-center justify-content-between"}
            >
              <div className={"d-flex align-items-center"}>
                <IcomoonReact
                  className={"custom-icon mr-2"}
                  iconSet={iconSet}
                  size={16}
                  icon="ventilation_icon-color"
                />
                <FormattedMessage
                  id="hm.label.ventilation"
                  defaultMessage=""
                  tagName={"h3"}
                >
                  {message => <h3 className={"mb-0"}>{message}</h3>}
                </FormattedMessage>
                {gravitational && (
                  <FormattedMessage
                    id={"hm.dropdown.ventilation.natural.warning"}
                    defaultMessage=""
                  >
                    {message => (
                      <span
                        title={message}
                        className={"text-danger humidification-icon ml-1"}
                      >
                        <FontAwesomeIcon icon="exclamation-triangle" />
                      </span>
                    )}
                  </FormattedMessage>
                )}
                {!this.props.referenceState &&
                  this.props.house.buildingType !== "new" && (
                    <FormattedMessage
                      id={`hm.optimizer.thermoderm.ventilation.warning`}
                      defaultMessage=""
                      tagName={"p"}
                    >
                      {message => (
                        <FontAwesomeIcon
                          icon="exclamation-triangle"
                          className={"ml-1 text-danger"}
                          title={message}
                        />
                      )}
                    </FormattedMessage>
                  )}
                <FormattedMessage
                  id={"hm.label.warning.recuperator.multi_house"}
                  defaultMessage=""
                >
                  {message => (
                    <span
                      title={message}
                      className={cx("text-danger humidification-icon ml-1", {
                        "d-none": !(
                          isMulti &&
                          (this.props.ventilation.airTightness ===
                            "hm.tightness.tight_recup" ||
                            this.props.ventilation.airTightness ===
                              "hm.tightness.standard_passive")
                        ),
                      })}
                    >
                      <FontAwesomeIcon icon="exclamation-triangle" />
                    </span>
                  )}
                </FormattedMessage>
                {needDefrosting && ventilationMethod.recuperator && (
                  <FormattedMessage
                    id="hm.label.defrosting.need"
                    defaultMessage=""
                  >
                    {message => (
                      <span
                        title={message}
                        className={cx("defrosting-icon ml-1")}
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={16}
                          icon={"bez_odmrazania-color"}
                        />
                      </span>
                    )}
                  </FormattedMessage>
                )}
                {ventilationMethod.recuperator && (
                  <FormattedMessage
                    id={
                      needHumidification
                        ? "hm.label.humidification.need"
                        : "hm.label.humidification.no_need"
                    }
                    defaultMessage=""
                  >
                    {message => (
                      <span
                        title={message}
                        className={cx("humidification-icon ml-1")}
                      >
                        <IcomoonReact
                          iconSet={iconSet}
                          size={16}
                          icon={
                            needHumidification
                              ? "brak_nawilzania_powietrza-color"
                              : "nawilzanie_powietrza"
                          }
                        />
                      </span>
                    )}
                  </FormattedMessage>
                )}
              </div>
              <FontAwesomeIcon
                icon="caret-down"
                className={cx("icon-caret-toggle ml-1", {
                  reversed: !this.state.show,
                })}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                <div
                  className={"row align-items-center justify-content-between"}
                >
                  <div className={"col-8 pr-1"}>
                    <FormattedMessage
                      id="hm.label.tightness"
                      defaultMessage=""
                      tagName={"p"}
                    />
                    <div className={"mb-2"}>
                      <DropDownList
                        id="airTightnessDropDownList"
                        dataProvider={this.airTightnessList}
                        labelField="name"
                        selectedIndex={this.props.ventilation.airTightness}
                        onChange={e => this.airTightnessChanged(e)}
                      />
                    </div>
                  </div>
                  <div className={"col-4 pl-1"}>
                    <FormattedMessage
                      id={"hm.dropdown.tightness.n50"}
                      defaultMessage=""
                      tagName={"p"}
                    >
                      {message => (
                        <p className={"mb-0 text-right"}>{message}</p>
                      )}
                    </FormattedMessage>
                    <div className={"mb-2 text-right"}>
                      <NumericInput
                        min={0}
                        max={10}
                        step={0.1}
                        precision={1}
                        parse={e => e.replace(",", ".")}
                        format={FormatNumericInput}
                        value={this.props.ventilation.n50}
                        onChange={e => this.n50Change(e)}
                        className={"short-input"}
                      />
                    </div>
                  </div>
                </div>

                {locationList.length > 1 && (
                  <FormattedMessage
                    id="hm.label.recuperator.location"
                    defaultMessage=""
                    tagName={"p"}
                  >
                    {message => <p>{message}</p>}
                  </FormattedMessage>
                )}
                {locationList.length > 1 && (
                  <div className={cx("mb-2")}>
                    <DropDownList
                      id="locationDropDown"
                      dataProvider={locationList}
                      labelField="name"
                      selectedIndex={this.props.ventilation.recuperatorLocation}
                      onChange={e => this.locationChanged(e)}
                    />
                  </div>
                )}
                <div
                  className={cx(
                    "row align-items-center justify-content-between"
                  )}
                >
                  <div
                    className={cx("col-8 pr-1", {
                      "col-12 pr-3":
                        this.props.house.buildingType === "new" ||
                        AirTightness.getAirTightnessById(
                          this.props.ventilation.airTightness
                        ).ventNatural === false,
                    })}
                  >
                    <FormattedMessage
                      id="hm.label.ventilation"
                      defaultMessage=""
                      tagName={"p"}
                    />
                    <div className={"mb-2"}>
                      <DropDownList
                        id="ventilationDropDown"
                        dataProvider={ventilationMethodList}
                        labelField="name"
                        selectedIndex={this.props.ventilation.ventilation}
                        onChange={e => this.ventilationChanged(e)}
                      />
                    </div>
                  </div>
                  <div
                    className={cx("col-4 pl-1", {
                      "d-none":
                        this.props.house.buildingType === "new" ||
                        AirTightness.getAirTightnessById(
                          this.props.ventilation.airTightness
                        ).ventNatural === false,
                    })}
                  >
                    <FormattedMessage
                      id={
                        this.props.ventilation.isMechanical
                          ? "hm.label.ventilation.efficiency"
                          : "hm.label.ventilation.patency"
                      }
                      defaultMessage=""
                      tagName={"p"}
                    >
                      {message => (
                        <p className={"mb-0 d-flex"}>
                          {message}
                          <FormattedMessage
                            id="hm.label.ventilation.patency.info"
                            defaultMessage=""
                          >
                            {message => (
                              <Popup
                                show
                                message={message}
                                iconClassName="ml-1 patency-info"
                              />
                            )}
                          </FormattedMessage>
                        </p>
                      )}
                    </FormattedMessage>

                    <div className={"mb-2 text-right"}>
                      <DropDownList
                        id="ventilationPatencyDropDown"
                        dataProvider={[
                          {
                            name: "hm.label.ventilation.patency.100",
                            value: 100,
                            data: {
                              id: 100,
                            },
                          },
                          {
                            name: "hm.label.ventilation.patency.66",
                            value: 66,
                            data: {
                              id: 66,
                            },
                          },
                          {
                            name: "hm.label.ventilation.patency.33",
                            value: 33,
                            data: {
                              id: 33,
                            },
                          },
                          {
                            name: "hm.label.ventilation.patency.0",
                            value: 0,
                            data: {
                              id: 0,
                            },
                          },
                        ]}
                        labelField="name"
                        selectedIndex={this.props.ventilation.patency}
                        onChange={e => this.ventilationPatencyChanged(e)}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={cx("mb-2", {
                    "d-none":
                      this.props.ventilation.ventilation ===
                      "hm.ventilation-type.natural",
                  })}
                >
                  <label className={"mb-0 d-flex align-items-center mr-3"}>
                    <input
                      type="checkbox"
                      name={"co2_sensor"}
                      checked={this.props.ventilation.co2Sensor}
                      onChange={e => this.co2SensorChanged(e.target.checked)}
                    />
                    <FormattedMessage id="hm.label.co2Sensor" defaultMessage="">
                      {message => <p className={"mb-0 ml-1"}>{message}</p>}
                    </FormattedMessage>
                  </label>
                </div>

                <div
                  className={cx("mb-2", {
                    "d-none":
                      (this.props.ventilation.airTightness !==
                        "hm.tightness.tight_recup" &&
                        this.props.ventilation.airTightness !==
                          "hm.tightness.standard_passive") ||
                      this.props.ventilation.ventilation !==
                        "hm.ventilation-type.natural",
                  })}
                >
                  <label className={"mb-0 d-flex align-items-center mr-3"}>
                    <input
                      type="checkbox"
                      name={"humidification"}
                      checked={
                        this.props.ventilation.humidification ||
                        (this.props.ventilation.ventilation !==
                          "hm.recuperator-type.cross" &&
                          this.props.ventilation.ventilation !==
                            "hm.ventilation-type.natural")
                      }
                      onChange={e =>
                        this.humidificationChanged(e.target.checked)
                      }
                      disabled={
                        this.props.ventilation.ventilation !==
                        "hm.recuperator-type.cross"
                      }
                    />
                    <FormattedMessage
                      id="hm.label.humidification"
                      defaultMessage=""
                    >
                      {message => <p className={"mb-0 ml-1"}>{message}</p>}
                    </FormattedMessage>
                  </label>
                </div>
                {this.props.invalidDataOptimizer && better && !worse && (
                  <ThermodermVentilation
                    ventilation={this.props.dataOptimizer[0]}
                    className={"insulation-ventilation"}
                    writeCache={() => this.props.writeCache()}
                  />
                )}
              </Card.Body>
            </Accordion.Collapse>
          </div>
          <Popup
            show={this.state.show}
            message={"hm.tooltip.ventilation"}
            placement={"right"}
          />
        </div>
      </Card>
    );
  }
}
const mapStateToProps = state => {
  const ventilation = getVentilation(state);
  const house = getHouse(state);
  return { ventilation, house };
};

export default injectIntl(connect(mapStateToProps)(VentilationBaloon));
