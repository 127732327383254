import { HouseTypeHelper } from "../../util/HouseTypeHelper";
import { BalanceValueHelper } from "../../core/calc/utils/BalanceValueHelper";
import cx from "classnames";
import Arrow from "./Arrow";
import React from "react";
import IcomoonReact from "icomoon-react";
import iconSet from "../../selection.json";
import "./Arrows.scss";
import { LabelBaloon } from "../Baloons/LabelBaloon";
import { LabelSystemBaloon } from "../Baloons/LabelSystemBaloon";
import { ConvertToUnit, fromKwhToWattsRatio } from "../Helpers/ConvertToUnit";
import living_needs from "./../../assets/images/icons/living_needs_icon-color.svg";
import ventilation_power_icon from "./../../assets/images/icons/ventilation_power_icon-color.svg";
import power_systems_icon from "./../../assets/images/icons/power_systems_icon-color.svg";
import { getPvEnergyCalculated } from "../../util/getPvEnergyCalculated";

export function LoadArrows(props) {
  const pvCalc = getPvEnergyCalculated(
    props.dataResults,
    props.livingNeedsChanged,
    props.livingNeeds
  );

  const collectorsYearValue = ConvertToUnit(
    props.dataResults.yearlyAggregatedConvertedData.enCollectorSolarGain,
    props.selectedUnit,
    props.waterPricePerKWh,
    props.waterEmissionCoefficient
  );

  const warmWaterYearValue = Math.round(
    ConvertToUnit(
      props.dataResults.yearlyAggregatedConvertedData.heatingWaterDeviceEnergy,
      props.selectedUnit,
      props.waterPricePerKWh,
      props.waterEmissionCoefficient
    ) + collectorsYearValue
  );

  return (
    <>
      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          props.dataResults.monthlyConvertedData[props.month].enWarmWater,
          warmWaterYearValue,
          props.selectedUnit
        )}
        wattValue={props.dataResults.yearlyAggregatedConvertedData.enWarmWater}
        displayPercents={true}
        selectedUnit={props.selectedUnit}
        valueNegative={false}
        class={"rotate90 column-reverse warm-water"}
        pricePerKWh={props.waterPricePerKWh}
        emissionCoefficient={props.waterEmissionCoefficient}
      />

      {!props.recuperator && (
        <Arrow
          dataResults={props.dataResults}
          value={props.propagateResults(
            props.dataResults.monthlyConvertedData[props.month].enAir,
            ConvertToUnit(
              props.dataResults.yearlyAggregatedConvertedData.enAir,
              props.selectedUnit,
              props.pricePerKWh,
              props.emissionCoefficient
            ),
            props.selectedUnit
          )}
          wattValue={props.dataResults.yearlyAggregatedConvertedData.enAir}
          displayPercents={true}
          selectedUnit={props.selectedUnit}
          valueNegative={false}
          class={"rotate180  airing"}
          pricePerKWh={props.pricePerKWh}
          emissionCoefficient={props.emissionCoefficient}
        />
      )}

      {!HouseTypeHelper.hasNeighboursDown(props.houseType) && (
        <Arrow
          dataResults={props.dataResults}
          value={props.propagateResults(
            props.dataResults.monthlyConvertedData[props.month].enFloor +
              props.dataResults.monthlyConvertedData[props.month].enFoundations,
            ConvertToUnit(
              props.dataResults.yearlyAggregatedConvertedData.enFloor +
                props.dataResults.yearlyAggregatedConvertedData.enFoundations,
              props.selectedUnit,
              props.pricePerKWh,
              props.emissionCoefficient
            ),
            props.selectedUnit
          )}
          wattValue={
            props.dataResults.yearlyAggregatedConvertedData.enFloor +
            props.dataResults.yearlyAggregatedConvertedData.enFoundations
          }
          displayPercents={true}
          selectedUnit={props.selectedUnit}
          valueNegative={false}
          unitName={props.unitName}
          class={"rotate90 column-reverse floor"}
          pricePerKWh={props.pricePerKWh}
          emissionCoefficient={props.emissionCoefficient}
        />
      )}

      {!HouseTypeHelper.hasNeighboursUp(props.houseType) && (
        <Arrow
          dataResults={props.dataResults}
          value={props.propagateResults(
            props.dataResults.monthlyConvertedData[props.month].enRoof,
            ConvertToUnit(
              props.dataResults.yearlyAggregatedConvertedData.enRoof,
              props.selectedUnit,
              props.pricePerKWh,
              props.emissionCoefficient
            ),
            props.selectedUnit
          )}
          wattValue={props.dataResults.yearlyAggregatedConvertedData.enRoof}
          displayPercents={true}
          selectedUnit={props.selectedUnit}
          valueNegative={false}
          class={"rotate270 column roof"}
          pricePerKWh={props.pricePerKWh}
          emissionCoefficient={props.emissionCoefficient}
        />
      )}

      {props.acData.airconditioning && (
        <Arrow
          dataResults={props.dataResults}
          value={props.propagateResults(
            props.dataResults.monthlyConvertedData[props.month]
              .airConditioningDeviceEnergy,
            ConvertToUnit(
              props.dataResults.yearlyAggregatedConvertedData
                .airConditioningDeviceEnergy,
              props.selectedUnit,
              props.electricityPricePerKWh,
              props.electricityEmissionCoefficient
            ),
            props.selectedUnit
          )}
          wattValue={
            props.dataResults.yearlyAggregatedConvertedData
              .airConditioningDeviceEnergy
          }
          displayPercents={false}
          selectedUnit={props.selectedUnit}
          valueNegative={false}
          class={"row-reverse air-conditioning"}
        />
      )}

      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          props.dataResults.monthlyConvertedData[props.month]
            .heatingSourceLoss +
            props.dataResults.monthlyConvertedData[props.month]
              .warmWaterHeatingSourceLoss,
          ConvertToUnit(
            props.dataResults.yearlyAggregatedConvertedData.heatingSourceLoss,
            props.selectedUnit,
            props.pricePerKWh,
            props.emissionCoefficient
          ) +
            ConvertToUnit(
              props.dataResults.yearlyAggregatedConvertedData
                .warmWaterHeatingSourceLoss,
              props.selectedUnit,
              props.waterPricePerKWh,
              props.waterEmissionCoefficient
            ),
          props.selectedUnit
        )}
        wattValue={
          props.dataResults.yearlyAggregatedConvertedData.heatingSourceLoss +
          props.dataResults.yearlyAggregatedConvertedData
            .warmWaterHeatingSourceLoss
        }
        displayPercents={false}
        selectedUnit={props.selectedUnit}
        valueNegative={false}
        class={"heat-loss"}
      />
      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          props.dataResults.monthlyConvertedData[props.month]
            .enElectricDevicesEnergyLossNormalized +
            props.dataResults.monthlyConvertedData[props.month]
              .enWaterElectricDevicesEnergyLoss,
          ConvertToUnit(
            props.dataResults.yearlyAggregatedConvertedData
              .enElectricDevicesEnergyLossNormalized +
              props.dataResults.yearlyAggregatedConvertedData
                .enWaterElectricDevicesEnergyLoss,
            props.selectedUnit,
            props.electricityPricePerKWh,
            props.electricityEmissionCoefficient
          ),
          props.selectedUnit
        )}
        wattValue={
          props.dataResults.yearlyAggregatedConvertedData
            .enElectricDevicesEnergyLossNormalized +
          props.dataResults.yearlyAggregatedConvertedData
            .enWaterElectricDevicesEnergyLoss
        }
        displayPercents={false}
        selectedUnit={props.selectedUnit}
        valueNegative={false}
        class={"rotate90 column-reverse heating-systems"}
      />
      <LabelBaloon
        id="labelBaloon"
        headerText={
          props.dataResults.yearlyAggregatedConvertedData.heatingSourceLoss +
            props.dataResults.yearlyAggregatedConvertedData
              .warmWaterHeatingSourceLoss >
          0
            ? "hm.label.heating.source_loss"
            : "hm.label.heating.source_gain"
        }
        name={"source_loss"}
        message="hm.tooltip.heatingdevice.loss"
        icon="loss_heating_device_icon-color"
      />
      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          props.dataResults.monthlyConvertedData[props.month].enVent +
            props.dataResults.monthlyConvertedData[props.month].enTightness,
          ConvertToUnit(
            props.dataResults.yearlyAggregatedConvertedData.enVent +
              props.dataResults.yearlyAggregatedConvertedData.enTightness,
            props.selectedUnit,
            props.pricePerKWh,
            props.emissionCoefficient
          ),
          props.selectedUnit
        )}
        wattValue={
          props.dataResults.yearlyAggregatedConvertedData.enVent +
          props.dataResults.yearlyAggregatedConvertedData.enTightness
        }
        displayPercents={true}
        selectedUnit={props.selectedUnit}
        valueNegative={false}
        class={"rotate180 ventilation"}
      />
      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          props.dataResults.monthlyConvertedData[props.month].enHumidification +
            props.dataResults.monthlyConvertedData[props.month].enRecuperator,
          ConvertToUnit(
            props.dataResults.yearlyAggregatedConvertedData.enHumidification +
              props.dataResults.yearlyAggregatedConvertedData.enRecuperator +
              props.dataResults.yearlyAggregatedConvertedData
                .enElectricVentilationEnergyLoss,
            props.selectedUnit,
            props.electricityPricePerKWh,
            props.electricityEmissionCoefficient
          ),
          props.selectedUnit
        )}
        wattValue={
          props.dataResults.yearlyAggregatedConvertedData.enHumidification +
          props.dataResults.yearlyAggregatedConvertedData.enRecuperator +
          props.dataResults.yearlyAggregatedConvertedData
            .enElectricVentilationEnergyLoss
        }
        displayPercents={true}
        selectedUnit={props.selectedUnit}
        valueNegative={false}
        class={"rotate90 column-reverse ventilation-systems"}
      />
      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          BalanceValueHelper.calcBalanceEnSolGainForMonth(
            props.dataResults,
            props.month
          ),
          ConvertToUnit(
            BalanceValueHelper.calcBalanceEnSolGain(props.dataResults),

            props.selectedUnit,
            props.pricePerKWh,
            props.emissionCoefficient
          ),
          props.selectedUnit
        )}
        wattValue={BalanceValueHelper.calcBalanceEnSolGain(props.dataResults)}
        displayPercents={false}
        selectedUnit={props.selectedUnit}
        valueNegative={true}
        class={"rotate180 row-reverse sun"}
        pricePerKWh={props.pricePerKWh}
        emissionCoefficient={props.emissionCoefficient}
      />

      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          props.dataResults.monthlyConvertedData[props.month]
            .enCollectorSolarGain,
          collectorsYearValue,
          props.selectedUnit
        )}
        wattValue={
          props.dataResults.yearlyAggregatedConvertedData.enCollectorSolarGain
        }
        displayPercents={false}
        selectedUnit={props.selectedUnit}
        valueNegative={true}
        class={"rotate270 column-reverse collector"}
      />
      <div
        className={cx("baloon collectors-icon", {
          "d-none":
            props.dataResults.yearlyAggregatedConvertedData
              .enCollectorSolarGain <= 0,
        })}
      >
        <span className={"baloon-icon "}>
          <IcomoonReact
            className={""}
            iconSet={iconSet}
            size={22}
            icon="collector_icon-color"
          />
        </span>
      </div>

      {props.dataResults.yearlyAggregatedConvertedData.enPhotovoltaicGain >
        0 && (
        <Arrow
          dataResults={props.dataResults}
          value={props.propagateResults(
            props.dataResults.monthlyConvertedData[props.month]
              .enPhotovoltaicGain,
            ConvertToUnit(
              pvCalc.sum * fromKwhToWattsRatio(),
              props.selectedUnit,
              props.electricityPricePerKWh,
              props.electricityEmissionCoefficient
            ),
            props.selectedUnit
          )}
          wattValue={pvCalc.sum * fromKwhToWattsRatio()}
          displayPercents={false}
          selectedUnit={props.selectedUnit}
          valueNegative={true}
          class={"rotate270 column-reverse photovoltaic"}
        />
      )}
      <div
        className={cx("baloon photovoltaic-icon", {
          "d-none":
            props.dataResults.yearlyAggregatedConvertedData
              .enPhotovoltaicGain <= 0,
        })}
      >
        <span className={"baloon-icon "}>
          <IcomoonReact
            className={""}
            iconSet={iconSet}
            size={22}
            icon="PV_icon-color"
          />
        </span>
      </div>

      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          BalanceValueHelper.calcBalanceEnByProductHeatForMonth(
            props.dataResults,
            props.month
          ),
          ConvertToUnit(
            BalanceValueHelper.calcBalanceEnByProductHeat(props.dataResults),
            props.selectedUnit,
            props.pricePerKWh,
            props.emissionCoefficient
          ),
          props.selectedUnit
        )}
        wattValue={BalanceValueHelper.calcBalanceEnByProductHeat(
          props.dataResults
        )}
        displayPercents={false}
        selectedUnit={props.selectedUnit}
        valueNegative={true}
        class={"rotate90 column person"}
        pricePerKWh={props.pricePerKWh}
        emissionCoefficient={props.emissionCoefficient}
      />

      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          props.dataResults.monthlyConvertedData[props.month].enWindows,
          ConvertToUnit(
            props.dataResults.yearlyAggregatedConvertedData.enWindows,
            props.selectedUnit,
            props.pricePerKWh,
            props.emissionCoefficient
          ),
          props.selectedUnit
        )}
        wattValue={props.dataResults.yearlyAggregatedConvertedData.enWindows}
        displayPercents={true}
        selectedUnit={props.selectedUnit}
        valueNegative={false}
        class={"rotate180  windows"}
        pricePerKWh={props.pricePerKWh}
        emissionCoefficient={props.emissionCoefficient}
      />

      <Arrow
        dataResults={props.dataResults}
        value={props.propagateResults(
          props.dataResults.monthlyConvertedData[props.month].enWalls,
          ConvertToUnit(
            props.dataResults.yearlyAggregatedConvertedData.enWalls +
              props.dataResults.yearlyAggregatedConvertedData.enDoor,
            props.selectedUnit,
            props.pricePerKWh,
            props.emissionCoefficient
          ),
          props.selectedUnit
        )}
        wattValue={
          props.dataResults.yearlyAggregatedConvertedData.enWalls +
          props.dataResults.yearlyAggregatedConvertedData.enDoor
        }
        displayPercents={true}
        selectedUnit={props.selectedUnit}
        valueNegative={false}
        class={"row-reverse wall"}
        pricePerKWh={props.pricePerKWh}
        emissionCoefficient={props.emissionCoefficient}
      />
      {props.dataResults.yearlyAggregatedConvertedData.enPhotovoltaicGain >
        0 && (
        <Arrow
          dataResults={props.dataResults}
          value={props.propagateResults(
            props.dataResults.monthlyConvertedData[props.month].enLivingNeeds,
            ConvertToUnit(
              props.dataResults.yearlyAggregatedConvertedData.enLivingNeeds,
              props.selectedUnit,
              props.electricityPricePerKWh,
              props.electricityEmissionCoefficient
            ),
            props.selectedUnit
          )}
          wattValue={
            props.dataResults.yearlyAggregatedConvertedData.enLivingNeeds
          }
          displayPercents={true}
          selectedUnit={props.selectedUnit}
          valueNegative={false}
          class={"rotate90 column living-needs"}
          pricePerKWh={props.electricityPricePerKWh}
          emissionCoefficient={props.electricityEmissionCoefficient}
        />
      )}
      {props.dataResults.yearlyAggregatedConvertedData.enPhotovoltaicGain >
        0 && (
        <LabelSystemBaloon
          headerText={"hm.label.living_needs"}
          name={"living_needs"}
          message="hm.tooltip.living_needs"
          icon={living_needs}
        />
      )}
      {props.dataResults.yearlyAggregatedConvertedData.enHumidification +
        props.dataResults.yearlyAggregatedConvertedData.enRecuperator +
        props.dataResults.yearlyAggregatedConvertedData
          .enElectricVentilationEnergyLoss >
        0 && (
        <LabelSystemBaloon
          headerText={"hm.label.ventilation_electric_system"}
          name={"ventilation_electric_system"}
          message="hm.tooltip.ventilation_electric_system"
          icon={ventilation_power_icon}
        />
      )}
      {props.dataResults.yearlyAggregatedConvertedData
        .enElectricDevicesEnergyLossNormalized +
        props.dataResults.yearlyAggregatedConvertedData
          .enWaterElectricDevicesEnergyLoss >
        0 && (
        <LabelSystemBaloon
          headerText={"hm.label.heat_electric_system"}
          name={"heat_electric_system"}
          message="hm.tooltip.heat_electric_system"
          icon={power_systems_icon}
        />
      )}
    </>
  );
}
