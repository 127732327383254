import React from "react";
import { getConversion } from "../../redux/selectors";
import { FormattedMessage, injectIntl } from "react-intl";
import connect from "react-redux/es/connect/connect";
import cx from "classnames";
import { ConversionUnits } from "../../constants/combo/ConversionUnits";
import { HeatMasterFormatter } from "../../util/HeatMasterFormatter";
import { fromWattsToKwhRatio } from "../Helpers/ConvertToUnit";

const Arrow = React.memo(props => {
  const getArrowWidth = () => {
    let value = Math.abs((props.wattValue * fromWattsToKwhRatio()) / 3);
    const calcValue = Math.max(Math.min(20000, value), 0);
    let arrowWidth = Math.round(0.1 * Math.pow(calcValue, 0.7));
    if (arrowWidth % 2 === 1) {
      arrowWidth = arrowWidth - 1;
    }

    if (value > 0) {
      arrowWidth = Math.max(4, arrowWidth);
    }

    return arrowWidth;
  };

  const displayNewValue = (value, unit, unitNameCode) => {
    const formattedValue = formatValue(value, unit, unitNameCode);
    const hideLabels = formattedValue === "0" || formattedValue === "0.0";

    if (!hideLabels) {
      return formattedValue;
    }
  };

  const setPercentageValue = (
    conversionUnit,
    value,
    monthlyData,
    yearlyData
  ) => {
    if (
      ConversionUnits.isMonthUnit(conversionUnit) ||
      conversionUnit === ConversionUnits.ENERGY_WATTS
    ) {
      return displayPercentageValue(value, monthlyData.getEnLossesForArrows());
    } else if (ConversionUnits.isYearUnit(conversionUnit)) {
      return displayPercentageValue(
        value,
        yearlyData.getEnLossesForArrows() * fromWattsToKwhRatio()
      );
    }
  };

  const displayPercentageValue = (singleValue, overallValue) => {
    const percentage = Math.abs((singleValue / overallValue) * 100);
    return `${Math.round(percentage)}%`;
  };

  const formatValue = (value, unit, unitName) => {
    let decimalNumbers = 0;
    if (ConversionUnits.isGJUnit(unit)) {
      decimalNumbers = ConversionUnits.isMonthUnit(unit) ? 3 : 2;
    }
    return HeatMasterFormatter.formatHMValue(value, decimalNumbers, true);
  };

  const showPercentageValue = (resultData, month) => {
    const monthlyData = resultData.monthlyConvertedData[month];
    return setPercentageValue(
      props.dataResults.conversionData.selectedUnit,
      props.wattValue * fromWattsToKwhRatio(),
      monthlyData,
      resultData.yearlyAggregatedConvertedData
    );
  };

  return (
    <div
      className={cx(
        `${props.class}-arrow`,
        "arrows text-center align-items-center",
        {
          loss: !props.valueNegative ? props.value > 0 : props.value < 0,
          gain: !props.valueNegative ? props.value < 0 : props.value > 0,
        },
        { "d-none": props.value === 0 },
        { "d-flex": props.value !== 0 }
      )}
    >
      <div>
        <p className={"label-value mb-0"}>
          {Math.abs(
            displayNewValue(
              props.value,
              props.selectedUnit,
              props.dataResults.conversionData.shortUnitName
            )
          )}
        </p>
        {props.children}
        <p className={"label-value mb-0"}>
          <FormattedMessage id={props.conversion.unitName} />
        </p>
      </div>
      <div className={"position-relative square"}>
        <div className={"arrow-wrapper"} style={{ height: getArrowWidth() }}>
          <div className={"arrow"} />
        </div>
        <p
          className={cx("percent-value", {
            "d-none": !props.displayPercents,
          })}
        >
          {showPercentageValue(props.dataResults, 0)}
        </p>
      </div>
    </div>
  );
});

Arrow.displayName = "Arrow";
const mapStateToProps = state => {
  const conversion = getConversion(state);
  return { conversion };
};

export default injectIntl(connect(mapStateToProps)(Arrow));
