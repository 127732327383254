import { AbstractSurfaceCalculator } from "./AbstractSurfaceCalculator";
import { HouseTypeHelper } from "../../../../util/HouseTypeHelper";
import { Directions } from "../../../../constants/Directions";
import { RoofType } from "../../../../constants/RoofType";

export class WallsToSurfaceCalculator extends AbstractSurfaceCalculator {
  calculate = (wallsData, surfaceData, roofData, houseType) => {
    let windowsSurfaceS = 0;
    let windowsSurfaceN = 0;
    let windowsSurfaceWE = 0;
    const totalSurface = surfaceData.totalSurface * 1.12;
    let windowsSurface = 0;
    let insulatedWallSurface =
      wallsData.length > 0 ? 0 : surfaceData.wallSurface;
    let nonInsulatedWallSurface = 0;

    wallsData.map(wall => {
      if (wall.insulated) {
        insulatedWallSurface += wall.width - wall.window;
      } else {
        nonInsulatedWallSurface += wall.width - wall.window;
      }
      windowsSurface += wall.window;
      if (
        wall.direction === Directions.WEST ||
        wall.direction === Directions.EAST ||
        wall.direction === Directions.NORTHWEST
      ) {
        windowsSurfaceWE += wall.window;
      } else if (
        wall.direction === Directions.NORTH ||
        wall.direction === Directions.NORTHEAST
      ) {
        windowsSurfaceN += wall.window;
      } else if (
        wall.direction === Directions.SOUTH ||
        wall.direction === Directions.SOUTHEAST ||
        wall.direction === Directions.SOUTHWEST
      ) {
        windowsSurfaceS += wall.window;
      }
      return wall;
    });

    surfaceData.floorArea = totalSurface / surfaceData.floorCount;
    surfaceData.floorSurface = surfaceData.floorArea;
    surfaceData.atticSurface = surfaceData.floorArea;

    if (wallsData.length > 0) {
      surfaceData.windowsSurfaceS = windowsSurfaceS;
      surfaceData.windowsSurfaceN = windowsSurfaceN;
      surfaceData.windowsSurfaceWE = windowsSurfaceWE;
      surfaceData.windowsSurface = windowsSurface;
      surfaceData.insulatedWallSurface = insulatedWallSurface;
      surfaceData.nonInsulatedWallSurface = nonInsulatedWallSurface;
      surfaceData.wallSurface = insulatedWallSurface + nonInsulatedWallSurface;
      surfaceData.roofWallSurface = 0;
      if (roofData.type === RoofType.FLAT) {
        surfaceData.atticWallSurface = 0;
        surfaceData.insulatedAtticWall = 0;
        surfaceData.nonInsulatedAtticWall = 0;
        surfaceData.roofSurface = surfaceData.floorArea;
      } else if (roofData.type === RoofType.COMPLICATED) {
        surfaceData.roofSurface = Math.abs(
          (surfaceData.floorArea /
            Math.cos(this.degreesToRadians(roofData.angle))) *
            1.05 *
            1.2
        );
      } else {
        surfaceData.roofSurface = Math.abs(
          (surfaceData.floorArea /
            Math.cos(this.degreesToRadians(roofData.angle))) *
            1.05
        );
      }
    }
    return surfaceData;
  };

  supportsHouseType = type => HouseTypeHelper.isTerraceType(type);
}
