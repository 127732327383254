import { InsulationElement } from "./InsulationElement";
import { InsulationElementType } from "../../constants/combo/InsulationElementType";

export class WindowElement extends InsulationElement {
  _windowType = -1;
  _winGain = 0;
  _shutters = false;

  _windowsType;
  _thermalBridgesType;

  constructor(windowsTypes) {
    super();
    this._windowsType = windowsTypes;
    this._winGain = windowsTypes.winGain;
  }

  toString = () =>
    "WindowElement: = {" +
    super.toString() +
    " [windowType=" +
    this._windowType +
    ",winGain=" +
    this._winGain +
    "]}";

  getElementType = () => InsulationElementType.WINDOW;

  get windowType() {
    return this._windowType;
  }

  set windowType(value) {
    this._windowType = value;
  }

  get winGain() {
    return this._winGain;
  }

  set winGain(value) {
    this._winGain = value;
  }

  get shutters() {
    return this._shutters;
  }

  set shutters(value) {
    this._shutters = value;
  }

  get thermalBridgesType() {
    return this._thermalBridgesType;
  }

  set thermalBridgesType(value) {
    this._thermalBridgesType = value;
  }

  get windowsType() {
    return this._windowsType;
  }

  set windowsType(value) {
    this._windowsType = value;
  }
}
