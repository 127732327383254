import React, { Component } from "react";
import HousePanel from "./components/HousePanel/HousePanel";

import "./App.scss";
import "./krolick.css";
import "./krolick2.css";

import { FormattedMessage, IntlProvider, addLocaleData } from "react-intl";
import localePL from "./locale/pl_PL/hm.json";
import localeEN from "./locale/en_US/hm.json";
import pl from "react-intl/locale-data/pl";
import en from "react-intl/locale-data/en";
import countriesXML from "./config.json";
import stationsXML from "./stations.json";
import dictionaryXML from "./dictionaries.json";
import iconSet from "./selection.json";
import IcomoonReact from "icomoon-react";

import logo from "./assets/images/logo-ekodom.svg";
import welcomeImage from "./assets/images/welcome-image.jpg";
import { HeatMasterController } from "./core/HeatMasterController";
import { EnvironmentalDataPreparer } from "./ui/builder/prepare/EnvironmentalDataPreparer";
import { CountryItemHelper } from "./config/CountryItemHelper";
import { StateConstants } from "./constants/StateConstants";
import { WindowResizer } from "./components/WindowResizer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "classnames";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faQuestion,
  faInfoCircle,
  faTimes,
  faChartBar,
  faLessThanEqual,
  faQuestionCircle,
  faSquareRootAlt,
  faCaretDown,
  faToggleOff,
  faToggleOn,
  faHome,
  faDollarSign,
  faCalculator,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import { BusinessConfigurationReader } from "./config/BusinessConfigurationReader";
import { CalculationStateController } from "./core/state/CalculationStateController";
import { CachedDataContextManager } from "./core/cache/CachedDataContextManager";
import { DataContext } from "./core/DataContext";
import { EnvironmentalData } from "./core/model/EnvironmentalData";
import { ConversionUnits } from "./constants/combo/ConversionUnits";
import { BalanceValueHelper } from "./core/calc/utils/BalanceValueHelper";
import { OptimizerDataContextValidator } from "./core/optimizer/OptimizerDataContextValidator";
import { connect } from "react-redux";
import { getBreakpoints, getConversion, getResults } from "./redux/selectors";

library.add([
  faQuestion,
  faInfoCircle,
  faTimes,
  faCalculator,
  faChartBar,
  faLessThanEqual,
  faHome,
  faQuestionCircle,
  faSquareRootAlt,
  faDollarSign,
  faCaretDown,
  faToggleOff,
  faToggleOn,
  faExclamationTriangle,
]);

addLocaleData([...pl, ...en]);

let localeConfig = {
  locale: "pl",
  messages: localePL,
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: props.locale,
      plotVisible: false,
      dataContext: false,
      dataResults: false,
      inCompareView: false,
      referenceState: true,
      month: 0,
      formattedReferenceValue: 0,
      formattedNewValue: 0,
      currency: "",
      showPopups: window.innerWidth >= 1366,
      showOptimizerPanel: false,
      firstCalculation: false,
      firstCalculationStep1: false,
      firstCalculationStep2: false,
      firstCalculationStep3: false,
      invalidDataOptimizer: false,
    };
    this.initHeatMaster();
    this.appInitialized();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.breakpoints.length !== this.props.breakpoints.length) {
      this.setState({
        showPopups: this.props.breakpoints.includes(1366),
      });
    }
  }

  onChangeLanguage = lang => {
    switch (lang) {
      case "pl":
        localeConfig.messages = localePL;
        break;
      case "en":
        localeConfig.messages = localeEN;
        break;
      default:
        localeConfig.messages = localePL;
        break;
    }
    this.setState({ locale: lang });
    localeConfig.locale = lang;
  };

  CURRENCY_EURO_LOCALE_CODE = "hm.currency.euro";
  YEAR_COST_SUFFIX_CODE = "year_cost";
  MONTH_COST_SUFFIX_CODE = "month_cost";

  groundBackgroundImgSrc = "./assets/images/backgrounds/background_grass.png";

  heatMasterController = new HeatMasterController();
  environmentalDataPreparer = new EnvironmentalDataPreparer();
  optimizerDataContextValidator;
  cachedDataContextManager;
  heatMasterLocaleManager;
  calculationStateController;
  businessConfigReader;

  balanceReportPanel;

  cachedData;

  startButtonVisible = true;

  plotVisible = false;
  comparingPlotVisible = false;
  stationsPanelVisible = false;

  footerTip;
  myFooterText;

  localeToggled = false;
  langChangeListeners = [];
  configItems;
  businessConfig;

  selectedLang;

  unitList = [
    {
      localeCode: "hm.dropdown.energy.consumption.kwh.year",
      data: ConversionUnits.ENERGY_KWH_PER_YEAR,
      labelUnit: "kWh/rok",
      shortUnit: "hm.unit.kwh.year",
      rawUnit: "hm.unit.kwh",
    },
    {
      localeCode: "hm.dropdown.energy.consumption.gj.year",
      data: ConversionUnits.ENERGY_GJ_PER_YEAR,
      labelUnit: "GJ/rok",
      shortUnit: "hm.unit.gj.year",
    },
    {
      localeCode: "hm.dropdown.emission.year",
      data: ConversionUnits.EMISSIONS_PER_YEAR,
      labelUnit: "CO2 kg/rok",
      shortUnit: "hm.unit.kg.year",
    },
    {
      localeCode: "hm.dropdown.energy.cost.year",
      data: ConversionUnits.COST_PER_YEAR,
      labelUnit: "zł/rok",
      shortUnit: "hm.currency.euro.year_cost",
    },
    {
      localeCode: "hm.dropdown.energy.cost",
      data: ConversionUnits.LOCAL_CURRENCY_COTS_PER_YEAR,
      labelUnit: "zł/rok",
      shortUnit: "hm.currency.euro.year_cost",
    },
  ];

  toggleOptimizerPanel = () => {
    this.setState(
      {
        showOptimizerPanel: !this.state.showOptimizerPanel,
        showPopups: false,
        invalidDataOptimizer: false,
      },
      () => {
        if (!this.state.showOptimizerPanel) {
          this.togglePopups();
        }
      }
    );
  };

  initHeatMaster = () => {
    this.businessConfigReader = new BusinessConfigurationReader(
      countriesXML,
      stationsXML,
      dictionaryXML,
      localeConfig.messages
    );
    this.calculationStateController = new CalculationStateController(
      this.heatMasterController
    );
    this.cachedDataContextManager = new CachedDataContextManager(
      this.calculationStateController
    );
    this.calculationStateController.setCacheManager(
      this.cachedDataContextManager
    );

    this.businessConfig = this.businessConfigReader.readBusinessConfiguration();
    this.configItems = this.businessConfig.countryItems;
    this.optimizerDataContextValidator = new OptimizerDataContextValidator(
      this.businessConfig.dictionaryConfig
    );
    this.heatMasterController.propagateBusinessConfiguration(
      this.businessConfig
    );
  };

  initEnvironmentalData = () => {
    let dataContext = this.initDataContextWithConfig();
    this.cachedDataContextManager.writeEnvironmentalData(
      dataContext.environmentalData
    );
  };

  appInitialized = () => {
    console.log("App initialized...");

    this.cachedData = this.cachedDataContextManager.readCache();
  };

  getConfigItemCode = cachedData => {
    if (cachedData != null && cachedData.selectedCountryRegion != null) {
      return cachedData.selectedCountryRegion;
    }
    return CountryItemHelper.getInitialConfigItemCode();
  };

  continueCalculation = () => {
    if (this.cachedData != null) {
      let currentDataContext = this.cachedData.getCurrentDataContext();
      let newDataContext = this.cachedData.newDataContext;
      let referenceDataContext = this.cachedData.referenceDataContext;

      if (currentDataContext != null) {
        currentDataContext.environmentalData = EnvironmentalDataPreparer.prepareFromCache(
          new EnvironmentalData(),
          this.cachedData.environmentalData
        );
        if (newDataContext != null && referenceDataContext != null) {
          this.heatMasterController.calculateAndSetViewReferenceState(
            this.cachedData
          );
          referenceDataContext.environmentalData = EnvironmentalDataPreparer.prepareFromCache(
            new EnvironmentalData(),
            this.cachedData.environmentalData
          );
          newDataContext.environmentalData = EnvironmentalDataPreparer.prepareFromCache(
            new EnvironmentalData(),
            this.cachedData.environmentalData
          );
          let comparingResults = this.heatMasterController.calculateAndPropagateComparingResult(
            referenceDataContext,
            newDataContext
          );
          this.comparingResultCalculated(comparingResults, this.state.month);
        } else {
          this.heatMasterController.calculateAndSetViewState(
            currentDataContext
          );
          this.setState({
            formattedReferenceValue: this.createFormattedValue(
              this.getProperValue(
                this.heatMasterController.getProcessResult(),
                this.state.month
              ),
              this.heatMasterController.getProcessResult().conversionData
                .selectedUnit,
              this.heatMasterController.getProcessResult().dataContext
                .currencyLocaleCode
            ),
            refProcessingResult: this.heatMasterController.getProcessResult(),
          });
        }

        this.calculationStateController.initStateController(this.cachedData);
        this.setState({
          dataContext: currentDataContext,
          inCompareView: newDataContext != null,
          dataResults: this.heatMasterController.getProcessResult(),
          referenceState: StateConstants.isInitialOrReference(
            parseInt(this.cachedData.state)
          ),
        });
        this.calculationStateController.updateStoreWithDataContext(
          currentDataContext
        );
      } else {
        this.newCalculation();
      }
    } else {
      this.newCalculation();
    }
  };

  comparingResultCalculated = (comparingResult, month) => {
    const refProcessingResult = comparingResult.refProcessingResult;
    const newProcessingResult = comparingResult.newProcessingResult;
    console.log(refProcessingResult);
    console.log(newProcessingResult);
    const formattedReferenceValue = this.createFormattedValue(
      this.getProperValue(refProcessingResult, month),
      refProcessingResult.conversionData.selectedUnit,
      refProcessingResult.dataContext.currencyLocaleCode
    );
    const formattedNewValue = this.createFormattedValue(
      this.getProperValue(newProcessingResult, month),
      newProcessingResult.conversionData.selectedUnit,
      newProcessingResult.dataContext.currencyLocaleCode
    );
    this.setState({
      formattedReferenceValue: formattedReferenceValue,
      formattedNewValue: formattedNewValue,
      refProcessingResult: refProcessingResult,
      newProcessingResult: newProcessingResult,
    });
  };

  createFormattedValue = (value, unit) => {
    if (value >= 10000000 && ConversionUnits.isEmisionUnit(unit)) {
      value = value / 1000;
    }
    return Math.round(value);
  };

  getUnitName = (unit, currencyLocaleCode) => {
    if (!ConversionUnits.isLocalCurrencyCostUnit(unit)) {
      return this.unitList.filter(item => item.data === unit)[0].shortUnit;
    }
    return currencyLocaleCode;
  };

  checkCompatibilityWithStandard = (refDataContext, newDataContext) => {
    this.setInvalidDataOptimizer(
      this.optimizerDataContextValidator.validate(
        this.state.referenceState,
        refDataContext,
        newDataContext,
        this.state.refProcessingResult,
        this.state.newProcessingResult
      )
    );
  };

  getProperValue = (resultData, month) => {
    if (ConversionUnits.isYearUnit(resultData.conversionData.selectedUnit)) {
      return BalanceValueHelper.calcEfficiencyAwareYearBalanceValue(resultData);
    } else {
      let convertedResult = resultData.monthlyConvertedData[month];
      return convertedResult.getEfficiencyAwareBalanceValue();
    }
  };

  newCalculation = () => {
    this.cachedDataContextManager.clearCache();
    this.cachedData = null;

    let dataContext = this.initDataContextWithConfig();
    this.cachedDataContextManager.writeEnvironmentalData(
      dataContext.environmentalData
    );

    this.cachedDataContextManager.writeCache();
    this.cachedData = this.cachedDataContextManager.readCache();
    let currentDataContext = this.cachedData.referenceDataContext;
    currentDataContext.environmentalData = EnvironmentalDataPreparer.prepareFromCache(
      new EnvironmentalData(),
      this.cachedData.environmentalData
    );
    let dataResults;
    let formattedReferenceValue;
    this.setState(
      {
        dataContext: currentDataContext,
        firstCalculation: true,
        firstCalculationStep1: true,
      },
      () => {
        this.calculationStateController.initStateController(this.cachedData);
        this.heatMasterController.startCalculationWithConfig(
          this.state.dataContext
        );
        dataResults = this.heatMasterController.getProcessResult();
        formattedReferenceValue = this.createFormattedValue(
          this.getProperValue(dataResults, this.state.month),
          dataResults.conversionData.selectedUnit,
          dataResults.dataContext.currencyLocaleCode
        );
        this.setState({
          dataResults: dataResults,
          formattedReferenceValue: formattedReferenceValue,
          refProcessingResult: dataResults,
        });
      }
    );
  };

  initDataContextWithConfig = () => {
    let regionItem = CountryItemHelper.getInitialOrCachedRegionItem(
      false,
      null,
      this.configItems
    );
    let countryItem =
      regionItem != null
        ? regionItem.country
        : CountryItemHelper.getInitialOrCachedCountryItem(
            false,
            null,
            this.configItems
          );

    let dataContext = new DataContext();
    let envDataConfig = null;
    if (regionItem != null) {
      envDataConfig = regionItem;
    } else {
      envDataConfig =
        countryItem != null && countryItem.stationsConfig.defaultStation != null
          ? countryItem.stationsConfig.defaultStation
          : countryItem;
    }
    dataContext.environmentalData = EnvironmentalDataPreparer.prepareFromConfig(
      new EnvironmentalData(),
      envDataConfig
    );
    dataContext.initialConfigItem = countryItem;
    dataContext.selectedCountryRegion =
      regionItem != null ? regionItem.code : countryItem.code;
    if (countryItem.stationsConfig != null) {
      dataContext.selectedStation =
        countryItem.stationsConfig.defaultStation.code;
    }
    return dataContext;
  };

  openManualPdf = () => {
    let url = "https://ziemianarozdrozu.pl/dl/Ekodom/Ekodom-Manual-PL.pdf";
    window.open(url, "_blank");
  };

  openTechPdf = () => {
    let url = "https://ziemianarozdrozu.pl/dl/Ekodom/Ekodom-Tech-PL.pdf";
    window.open(url, "_blank");
  };

  openAboutPdfFile = () => {
    let url = "https://ziemianarozdrozu.pl/dl/Ekodom/Ekodom-Team-PL.pdf";
    window.open(url, "_blank");
  };

  writeCacheAndSetDataContext = () => {
    let results;
    let formattedReferenceValue;
    this.cachedDataContextManager.writeCache();
    this.cachedData = this.cachedDataContextManager.readCache();
    let currentDataContext = this.cachedData.getCurrentDataContext();
    currentDataContext.environmentalData = EnvironmentalDataPreparer.prepareFromCache(
      new EnvironmentalData(),
      this.cachedData.environmentalData
    );
    let newDataContext = this.cachedData.newDataContext;
    let referenceDataContext = this.cachedData.referenceDataContext;
    this.heatMasterController.resetDataContext(currentDataContext);

    this.setState(
      {
        dataContext: currentDataContext,
      },
      () => {
        this.calculationStateController.initStateController(this.cachedData);

        results = this.heatMasterController.performActualCalculation(
          this.state.dataContext
        );
        referenceDataContext.environmentalData = EnvironmentalDataPreparer.prepareFromCache(
          new EnvironmentalData(),
          this.cachedData.environmentalData
        );

        if (newDataContext != null) {
          newDataContext.environmentalData = EnvironmentalDataPreparer.prepareFromCache(
            new EnvironmentalData(),
            this.cachedData.environmentalData
          );
          let comparingResults = this.heatMasterController.calculateAndPropagateComparingResult(
            referenceDataContext,
            newDataContext
          );
          this.comparingResultCalculated(comparingResults, this.state.month);
        } else {
          formattedReferenceValue = this.createFormattedValue(
            this.getProperValue(results, this.state.month),
            results.conversionData.selectedUnit,
            results.dataContext.currencyLocaleCode
          );
          this.setState({
            refProcessingResult: results,
            formattedReferenceValue: formattedReferenceValue,
          });
        }
        if (this.state.showOptimizerPanel) {
          this.checkCompatibilityWithStandard(
            referenceDataContext,
            newDataContext
          );
        }

        this.setState({
          dataResults: results,
        });
      }
    );
  };

  toggleCompareView = () => {
    this.setState({
      inCompareView: true,
    });
  };

  toggleReferenceState = state => {
    this.setState({
      referenceState: state,
    });
  };

  togglePopups = () => {
    this.setState(
      {
        showPopups: !this.state.showPopups,
        showOptimizerPanel: false,
        invalidDataOptimizer: false,
      },
      () => {
        let elems = document.querySelectorAll(".baloon");
        if (this.state.showPopups) {
          [].forEach.call(elems, function(el) {
            el.classList.remove("hidden");
          });
        } else {
          [].forEach.call(elems, function(el) {
            el.classList.add("hidden");
          });
        }
      }
    );
  };

  setInvalidDataOptimizer = invalidData => {
    this.setState({
      invalidDataOptimizer: invalidData,
    });
  };

  toggleFirstCalculation = e => {
    this.setState({
      [e]: !this.state[e],
    });
  };

  render() {
    return (
      <IntlProvider
        locale={localeConfig.locale}
        messages={localeConfig.messages}
      >
        <div className="container-fluid ">
          <WindowResizer />
          <div className="navbar-header no-gutters">
            <div className="row">
              <div className="col-6">
                <a className="navbar-brand" title="logo" href="/">
                  <img src={logo} className="logo" alt="" />
                </a>
              </div>
              <div className="help-icons col-6 d-flex justify-content-end align-items-center">
                <p className="showPopups mb-0 mx-1 d-flex align-items-center">
                  <FormattedMessage
                    id="hm.change.view"
                    defaultMessage=""
                    tagName={"span"}
                  >
                    {message => (
                      <span
                        className={"change-view pointer"}
                        onClick={() => this.togglePopups()}
                      >
                        {message}
                      </span>
                    )}
                  </FormattedMessage>
                  <FontAwesomeIcon
                    icon={cx({
                      "toggle-off": !this.state.showPopups,
                      "toggle-on": this.state.showPopups,
                    })}
                    className={"pointer mr-2"}
                    onClick={() => this.togglePopups()}
                  />
                </p>
                <FormattedMessage
                  id="hm.tooltip.button.manual"
                  defaultMessage=""
                >
                  {message => (
                    <p
                      className="helpBtn mb-0 mx-1"
                      onClick={() => this.openManualPdf()}
                      title={message}
                    >
                      <IcomoonReact
                        className={"pointer mr-2"}
                        iconSet={iconSet}
                        size={26}
                        icon={"howitworks_icon"}
                      />
                    </p>
                  )}
                </FormattedMessage>
                <FormattedMessage
                  id="hm.tooltip.button.how_to"
                  defaultMessage=""
                >
                  {message => (
                    <p
                      className="techBtn mb-0 mx-1"
                      onClick={() => this.openTechPdf()}
                      title={message}
                    >
                      <IcomoonReact
                        className={"pointer mr-2"}
                        iconSet={iconSet}
                        size={26}
                        icon={"algorithms_icon"}
                      />
                    </p>
                  )}
                </FormattedMessage>
                <FormattedMessage
                  id="hm.tooltip.button.about"
                  defaultMessage=""
                >
                  {message => (
                    <p
                      className="aboutBtn mb-0 mx-1"
                      onClick={() => this.openAboutPdfFile()}
                      title={message}
                    >
                      <IcomoonReact
                        className={"pointer mr-2"}
                        iconSet={iconSet}
                        size={26}
                        icon={"aboutUs_icon_2"}
                      />
                    </p>
                  )}
                </FormattedMessage>
              </div>
            </div>
          </div>
          {!this.state.dataResults && (
            <div
              className={"welcome-image"}
              style={{ backgroundImage: "url(" + welcomeImage + ")" }}
            />
          )}
          <HousePanel
            businessConfig={this.businessConfig}
            heatMasterController={this.heatMasterController}
            writeCache={() => this.writeCacheAndSetDataContext()}
            writeDataToBothCache={data =>
              this.cachedDataContextManager.writeDataToBothCache(data)
            }
            writeToSelectedStateCache={(data, state) =>
              this.cachedDataContextManager.writeToSelectedStateCache(
                data,
                state
              )
            }
            writeEnvironmentalData={envData =>
              this.cachedDataContextManager.writeEnvironmentalData(envData)
            }
            dataResults={this.state.dataResults}
            calculationStateController={this.calculationStateController}
            cachedData={this.cachedData}
            initEnvironmentalData={() => this.initEnvironmentalData()}
            toggleCompareView={() => this.toggleCompareView()}
            inCompareView={this.state.inCompareView}
            toggleReferenceState={state => this.toggleReferenceState(state)}
            referenceState={this.state.referenceState}
            month={this.state.month}
            formattedNewValue={this.state.formattedNewValue}
            formattedReferenceValue={this.state.formattedReferenceValue}
            refProcessingResult={this.state.refProcessingResult}
            newProcessingResult={this.state.newProcessingResult}
            unitList={this.unitList}
            onChangeLanguage={lang => this.onChangeLanguage(lang)}
            continueCalculation={() => this.continueCalculation()}
            newCalculation={() => this.newCalculation()}
            showPanels={this.state.showPopups}
            toggleOptimizerPanel={() => this.toggleOptimizerPanel()}
            invalidDataOptimizer={this.state.invalidDataOptimizer}
            showOptimizerPanel={this.state.showOptimizerPanel}
            firstCalculation={this.state.firstCalculation}
            firstCalculationStep1={this.state.firstCalculationStep1}
            firstCalculationStep2={this.state.firstCalculationStep2}
            firstCalculationStep3={this.state.firstCalculationStep3}
            toggleFirstCalculation={e => this.toggleFirstCalculation(e)}
          />
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  const results = getResults(state);
  const conversion = getConversion(state);
  const breakpoints = getBreakpoints(state);

  return { results, conversion, breakpoints };
};
export default connect(mapStateToProps)(App);
