export class ConversionUnits {
  static COST_PER_MONTH = 0;
  static COST_PER_YEAR = 1;
  static ENERGY_KWH_PER_MONTH = 2;
  static ENERGY_KWH_PER_YEAR = 3;
  static ENERGY_WATTS = 4;
  static ENERGY_GJ_PER_YEAR = 5;
  static ENERGY_GJ_PER_MONTH = 6;
  static EMISSIONS_PER_YEAR = 7;
  static LOCAL_CURRENCY_COST_PER_MONTH = 8;
  static LOCAL_CURRENCY_COTS_PER_YEAR = 9;

  static getRatio = (unit, ratioCluster) => {
    if (unit === ConversionUnits.ENERGY_WATTS) {
      return ratioCluster.toWattsRatio;
    }
    if (ConversionUnits.isGJUnit(unit)) {
      return ratioCluster.toGJRatio;
    }
    if (ConversionUnits.isKiloWattsUnit(unit)) {
      return ratioCluster.tokWhRatio;
    }
    if (ConversionUnits.isCostUnit(unit)) {
      return ratioCluster.toCostRatio;
    }
    return 1;
  };

  static isMonthUnit = unit =>
    unit === ConversionUnits.ENERGY_WATTS ||
    unit === ConversionUnits.ENERGY_KWH_PER_MONTH ||
    unit === ConversionUnits.COST_PER_MONTH ||
    unit === ConversionUnits.ENERGY_GJ_PER_MONTH ||
    unit === ConversionUnits.LOCAL_CURRENCY_COST_PER_MONTH;

  static isYearUnit = unit =>
    unit === ConversionUnits.ENERGY_KWH_PER_YEAR ||
    unit === ConversionUnits.COST_PER_YEAR ||
    unit === ConversionUnits.ENERGY_GJ_PER_YEAR ||
    unit === ConversionUnits.EMISSIONS_PER_YEAR ||
    unit === ConversionUnits.LOCAL_CURRENCY_COTS_PER_YEAR;

  static isKiloWattsUnit = unit =>
    unit === ConversionUnits.ENERGY_KWH_PER_YEAR ||
    unit === ConversionUnits.ENERGY_KWH_PER_MONTH;

  static isCostUnit = unit =>
    unit === ConversionUnits.COST_PER_MONTH ||
    unit === ConversionUnits.COST_PER_YEAR ||
    unit === ConversionUnits.LOCAL_CURRENCY_COST_PER_MONTH ||
    unit === ConversionUnits.LOCAL_CURRENCY_COTS_PER_YEAR;

  static isEuroCostUnit = unit =>
    unit === ConversionUnits.COST_PER_MONTH ||
    unit === ConversionUnits.COST_PER_YEAR;

  static isLocalCurrencyCostUnit = unit =>
    unit === ConversionUnits.LOCAL_CURRENCY_COST_PER_MONTH ||
    unit === ConversionUnits.LOCAL_CURRENCY_COTS_PER_YEAR;

  static isEnergyUnit = unit =>
    unit === ConversionUnits.ENERGY_KWH_PER_YEAR ||
    unit === ConversionUnits.ENERGY_KWH_PER_MONTH ||
    unit === ConversionUnits.ENERGY_GJ_PER_MONTH ||
    unit === ConversionUnits.ENERGY_GJ_PER_YEAR;

  static isGJUnit = unit =>
    unit === ConversionUnits.ENERGY_GJ_PER_YEAR ||
    unit === ConversionUnits.ENERGY_GJ_PER_MONTH;

  static isEmisionUnit = unit => unit === ConversionUnits.EMISSIONS_PER_YEAR;

  static isEfficiencyAwareUnit = unit =>
    ConversionUnits.isCostUnit(unit) || ConversionUnits.isEmisionUnit(unit);

  static getValueByUnit = (monthValue, yearValue, conversionUnit) => {
    if (ConversionUnits.isMonthUnit(conversionUnit)) {
      return monthValue;
    } else if (ConversionUnits.isYearUnit(conversionUnit)) {
      return yearValue;
    } else if (conversionUnit === ConversionUnits.ENERGY_WATTS) {
      return monthValue;
    }
    return monthValue;
  };
}
