import React, { useState } from "react";
import "./Welcome.scss";
import { Button, Modal } from "react-bootstrap";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import logo from "../../assets/images/logo-ekodom.svg";
import logoue from "../../assets/images/logo-ue.png";
import logotypy from "../../assets/images/logo-stopka.png";
import flagPL from "../../assets/images/flags/FlagPL.png";
import flagEN from "../../assets/images/flags/FlagEnglish.jpg";
import cx from "classnames";
import { Image } from "../Helpers/Image";

const Flag = props => {
  return (
    <img
      src={props.src}
      alt={props.code}
      className="flag"
      onClick={props.onClick}
    />
  );
};

const Welcome = props => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div className={"welcome"}>
      <Modal show={show} backdrop={"static"} className={"welcome"}>
        <Modal.Header>
          <Flag
            src={flagEN}
            onClick={() => props.onChangeLanguage("en")}
            code="en"
          />

          <Flag
            src={flagPL}
            onClick={() => props.onChangeLanguage("pl")}
            code="pl"
          />
        </Modal.Header>
        <Modal.Body>
          <p className={"release"}>1-16.12.2021</p>
          <img src={logoue} className="logo logoue" alt="" />
          <a className="navbar-brand" title="logo" href="/">
            <img src={logo} className="logo" alt="" />
          </a>
          <FormattedHTMLMessage id="hm.label.main.title" defaultMessage="" />
          <div className={"mb-2 text-center"}>
            <Button
              className={cx({ "d-none": !props.cachedData })}
              variant="secondary"
              onClick={() => {
                props.continueCalculation();
                handleClose();
              }}
            >
              <FormattedMessage
                id="hm.button.continue"
                defaultMessage="Kontynuuj"
              />
            </Button>
          </div>
          <FormattedMessage id="hm.button.new_calc" tagName={"h4"}>
            {message => <h4 className={"text-center mt-3"}>{message}</h4>}
          </FormattedMessage>
          <div className={"mb-2 text-center"}>
            <Button
              className={"ml-2"}
              variant="secondary"
              onClick={() => {
                handleClose();
                props.initEnvironmentalData();
                props.newCalculationWithStandard(
                  props.houseStandardTypeList
                    .filter(
                      item => item.data.houseStandardTypeModel.latest === true
                    )
                    .map(item => item.data.id)
                );
              }}
            >
              <FormattedMessage
                id="hm.button.new_calc_new"
                defaultMessage="Nowy budynek"
              />
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                handleClose();
                props.newCalculation("current");
              }}
            >
              <FormattedMessage
                id="hm.button.new_calc_old"
                defaultMessage="Istniejący budynek"
              />
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer className={"d-flex justify-content-around flex-wrap"}>
          <p>
            <Image
              id={"logo-wfos"}
              className={'logotypy w-100"'}
              visible={true}
              src={logotypy}
            />
          </p>
          <a
            href={"https://wfosigw.wroclaw.pl/"}
            className={"d-flex col-6 m-0 justify-content-center"}
          >
            <Image
              id={"logo-wfos"}
              className={"partner-logo"}
              visible={true}
              src={"./assets/images/logo/logo-wfos.svg"}
            />
          </a>

          <a
            href={"https://www.wwf.pl/"}
            className={"d-flex col-3 m-0 justify-content-center"}
          >
            <Image
              id={"logo-wwf"}
              className={"partner-logo"}
              visible={true}
              src={"./assets/images/logo/logo-wwf.svg"}
            />
          </a>
          <a
            href={"http://www.fer.org.pl/"}
            className={"d-flex col-3 m-0 justify-content-center"}
          >
            <Image
              id={"logo-fer"}
              className={"partner-logo"}
              visible={true}
              src={"./assets/images/logo/logo-fer.png"}
            />
          </a>
          <p className={"w-100 lh-100 mt-3 text-medium"}>
            <FormattedHTMLMessage id="hm.label.sponsor" defaultMessage="span" />
          </p>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Welcome;
