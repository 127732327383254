import { combineReducers } from "redux";
import windows from "./windows";
import ventilation from "./ventilation";
import persons from "./persons";
import insulation from "./insulation";
import house from "./house";
import heating from "./heating";
import conversion from "./conversion";
import photovoltaic from "./photovoltaic";
import airconditioning from "./airconditioning";
import results from "./results";
import insolation from "./insolation";
import reports from "./reports";
import costs from "./costs";
import { ui } from "../ui/reducer";
export default combineReducers({
  windows,
  ventilation,
  persons,
  insulation,
  house,
  heating,
  photovoltaic,
  conversion,
  airconditioning,
  insolation,
  reports,
  results,
  costs,
  ui,
});
