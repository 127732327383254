export class NaturalUnit {
  static naturalUnits = [];

  _code;

  static TONNE = new NaturalUnit("hm.natural_unit.tonne");
  static KUBIK1 = new NaturalUnit("hm.natural_unit.kubik1");
  static KUBIK2 = new NaturalUnit("hm.natural_unit.kubik2");
  static M3 = new NaturalUnit("hm.natural_unit.m3");
  static KG = new NaturalUnit("hm.natural_unit.kg");
  static GJ = new NaturalUnit("hm.natural_unit.gj");
  static KWH = new NaturalUnit("hm.natural_unit.kwh");

  constructor(code) {
    NaturalUnit.naturalUnits[code] = this;
    this._code = code;
  }

  get code() {
    return this._code;
  }

  static getNaturalUnitByCode = code => {
    return NaturalUnit.naturalUnits[code];
  };
}
