export class SurfaceData {
  floorCount = -1;
  floorHeight = -1;
  totalSurface = -1;

  floorArea = -1;
  floorSurface = -1;
  floorSurfaceC = -1;
  floorSurfaceR = -1;

  roofSurface = -1;
  atticSurface = 0;
  wallSurface = -1;

  atticWallSurface = -1;
  roofWallSurface = -1;
  insulatedWallSurface = -1;
  nonInsulatedWallSurface = -1;
  insulatedAtticWall = -1;
  nonInsulatedAtticWall = -1;

  windowsSurface = -1;
  windowsSurfaceS = -1;
  windowsSurfaceWE = -1;
  windowsSurfaceN = -1;

  toString = () =>
    "SurfaceData = [floorSurfaceC = " +
    this.floorSurfaceC +
    ",floorSurfaceR=" +
    this.floorSurfaceR +
    "\n,wallSurface = " +
    this.wallSurface +
    ",roofSurface=" +
    this.roofSurface +
    ",windowsSurface= " +
    this.windowsSurface +
    ",windowsSurfaceS= " +
    this.windowsSurfaceS +
    "\n" +
    ",windowsSurfaceN=" +
    this.windowsSurfaceN +
    ",windowsSurfaceWE= " +
    this.windowsSurfaceWE;

  constructor(floorCount, floorHeight, totalSurface, atticSurface) {
    this.floorCount = floorCount;
    this.floorHeight = floorHeight;
    this.totalSurface = totalSurface;
    this.atticSurface = atticSurface;
  }
}
