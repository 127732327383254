import { AirTightness } from "../../../config/dictionary/model/AirTightness";
import { VentilationFrequency } from "../../../constants/combo/VentilationFrequency";

export const VentilationReducer = () => {
  const tight = {
    co2Sensor: true,
    gwcSet: false,
    isMechanical: true,
    ventilationFreq: VentilationFrequency.STANDARD,
    airTightness: "hm.tightness.tight_recup",
    n50: AirTightness.getAirTightnessById("hm.tightness.tight_recup").value,
    ventilation: "hm.recuperator-type.cross",
  };

  const passive = {
    ...tight,
    airTightness: "hm.tightness.standard_passive",
    n50: AirTightness.getAirTightnessById("hm.tightness.standard_passive")
      .value,
  };

  return [
    { ...tight },
    { ...tight, ventilation: "hm.recuperator-type.rotary" },
    { ...tight, ventilation: "hm.recuperator-type.countercurrent" },
    { ...tight, ventilation: "hm.recuperator-type.countercurrent_entalpic" },
    { ...tight, ventilation: "hm.recuperator-type.countercurrent_regen" },
    { ...passive },
    { ...passive, ventilation: "hm.recuperator-type.rotary" },
    { ...passive, ventilation: "hm.recuperator-type.countercurrent" },
    { ...passive, ventilation: "hm.recuperator-type.countercurrent_entalpic" },
    { ...passive, ventilation: "hm.recuperator-type.countercurrent_regen" },
  ];
};
