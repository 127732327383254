import {
  UPDATE_AIRCONDITIONING,
  UPDATE_AIRCONDITIONING_BY_ID,
} from "../actionTypes";

const initialState = {
  airconditioning: false,
  temp: 25,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AIRCONDITIONING: {
      const data = action.payload.data;
      return {
        ...state,
        ...data,
      };
    }
    case UPDATE_AIRCONDITIONING_BY_ID: {
      const { id, value } = action.payload;
      return {
        ...state,
        [id]: value,
      };
    }
    default:
      return state;
  }
}
