import React from "react";
import cx from "classnames";

export const Image = React.memo(props => {
  const {
    src,
    alt,
    id,
    style,
    visible,
    className,
    width,
    height,
    hover,
    title,
  } = props;
  return (
    <img
      src={src}
      alt={alt ? alt : ""}
      id={id}
      style={style}
      className={cx({ "d-none": !visible }, className)}
      width={width ? `${width}px` : "auto"}
      height={height ? `${height}px` : "auto"}
      onMouseEnter={e => (e.currentTarget.src = hover ? hover : src)}
      onMouseLeave={e => (e.currentTarget.src = src)}
      onClick={() => (props.onClick ? props.onClick() : "")}
      title={title ? title : ""}
    />
  );
});
Image.displayName = "Image";
