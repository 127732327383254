import { EnergyClass } from "../constants/EnergyClass";

export class EnergyClassHelper {
  static energyClasses = [];
  static instance = new EnergyClassHelper();

  constructor() {
    EnergyClassHelper.energyClasses.push(
      new EnergyClass(
        250,
        Number.MAX_SAFE_INTEGER,
        "F",
        "hm.label.energy_class.very_poor",
        "#7F0000"
      )
    );
    EnergyClassHelper.energyClasses.push(
      new EnergyClass(150, 250, "E", "hm.label.energy_class.poor", "#E80000")
    );
    EnergyClassHelper.energyClasses.push(
      new EnergyClass(100, 150, "D", "hm.label.energy_class.average", "#F4007A")
    );
    EnergyClassHelper.energyClasses.push(
      new EnergyClass(
        80,
        100,
        "C",
        "hm.label.energy_class.saving.average",
        "#dec102"
      )
    );
    EnergyClassHelper.energyClasses.push(
      new EnergyClass(45, 80, "B", "hm.label.energy_class.saving", "#99BB31")
    );
    EnergyClassHelper.energyClasses.push(
      new EnergyClass(
        25,
        45,
        "A",
        "hm.label.energy_class.saving.very_good",
        "#5BC117"
      )
    );
    EnergyClassHelper.energyClasses.push(
      new EnergyClass(15, 25, "A+", "hm.label.energy_class.low", "#008000")
    );
    EnergyClassHelper.energyClasses.push(
      new EnergyClass(
        0,
        15,
        "A++",
        "hm.label.energy_class.passive",
        "green",
        true
      )
    );
  }

  static getEnergyClass = value => {
    for (let i = 0; i < EnergyClassHelper.energyClasses.length; i++) {
      let energyClass = EnergyClassHelper.energyClasses[i];
      if (energyClass.contains(value)) {
        return energyClass;
      }
    }
    return null;
  };
}
