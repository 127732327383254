import { OptimizerResultsState } from "../OptimizerResultsState";
import { EnergyCalcHelper } from "../../calc/monthly/EnergyCalcHelper";
import { VentilationMethod } from "../../../config/dictionary/model/VentilationMethod";
import { HouseTypeHelper } from "../../../util/HouseTypeHelper";
import { RecuperatorLocation } from "../../../constants/combo/RecuperatorLocation";

export class VentilationOptimizer {
  constructor(
    isInReferenceState,
    dictionary,
    buildingType,
    refPricePerKWh,
    newPricePerKWh
  ) {
    this.elementList = dictionary;
    this.buildingType = buildingType;
    this.refPricePerKWh = refPricePerKWh;
    this.newPricePerKWh = newPricePerKWh;
    this.isInReferenceState = isInReferenceState;
  }

  elementList;

  validate = (
    type,
    result,
    element,
    newElement,
    houseType,
    personNumber,
    surface,
    refCosts,
    newCosts
  ) => {
    result.state = OptimizerResultsState.THE_SAME;
    result.refItem = this.elementList.filter(
      item => item.id === element.ventilationMethodObject.id
    )[0];
    result.newItem = this.elementList.filter(
      item => item.id === newElement.ventilationMethodObject.id
    )[0];
    result.obsolescence = result.newItem.obsolescence;
    let tightnessCostNew = 0;
    let co2SensorCost = 0;
    let co2SensorCostNew = 0;
    if (
      element.ventilationMethodObject.efficiency >
      newElement.ventilationMethodObject.efficiency
    ) {
      result.state = OptimizerResultsState.WORSE;
    } else if (
      element.ventilationMethodObject.efficiency <
      newElement.ventilationMethodObject.efficiency
    ) {
      result.state = OptimizerResultsState.BETTER;

      result.refPricePerKWh = this.refPricePerKWh;
      result.newPricePerKWh = this.newPricePerKWh;

      const oldCosts = this.selectProperDevice(
        element,
        result.refItem,
        houseType,
        surface,
        personNumber
      );
      const costs = this.selectProperDevice(
        newElement,
        result.newItem,
        houseType,
        surface,
        personNumber
      );

      if (
        this.buildingType === "current" &&
        newElement.airTightness.id === "hm.tightness.standard_passive" &&
        element.airTightness.id !== "hm.tightness.standard_passive"
      ) {
        tightnessCostNew = 20 * surface + 2000;
      }

      element = {
        price: oldCosts.price ? oldCosts.price : 0,
        priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        work: oldCosts.work ? oldCosts.work : 0,
        workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        pricePerKWh: this.refPricePerKWh,
        labelKey: element.ventilationMethodObject.labelKey,
        efficiency: element.ventilationMethodObject.efficiency,
        co2Sensor: element.co2Sensor,
        co2SensorCost: element.co2Sensor ? 600 : 0,
        tightness: element.airTightness.labelKey,
        tightnessValue: element.airTightness.value,
        tightnessCost: 0,
        tightnessShow: false,
        obsolescence: 25,
      };

      newElement = {
        price: costs.price ? costs.price : 0,
        priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        work: costs.work ? costs.work : 0,
        workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        pricePerKWh: this.refPricePerKWh,
        labelKey: newElement.ventilationMethodObject.labelKey,
        efficiency: newElement.ventilationMethodObject.efficiency,
        co2Sensor: newElement.co2Sensor,
        co2SensorCost: newElement.co2Sensor ? 600 : 0,
        tightness: newElement.airTightness.labelKey,
        tightnessValue: newElement.airTightness.value,
        tightnessCost: tightnessCostNew,
        tightnessShow: tightnessCostNew > 0,
        obsolescence: 25,
      };
      result.refItem = element;
      result.newItem = newElement;

      let materialCost = element.price;
      let newMaterialCost = newElement.price;
      let workCost = element.work;
      let newWorkCost = newElement.work;

      if (refCosts[type + "_material_changed"]) {
        materialCost = refCosts[type + "_material"];
      }
      if (newCosts[type + "_material_changed"]) {
        newMaterialCost = newCosts[type + "_material"];
      }
      if (refCosts[type + "_work_changed"]) {
        workCost = refCosts[type + "_work"];
      }
      if (newCosts[type + "_work_changed"]) {
        newWorkCost = newCosts[type + "_work"];
      }

      if (newCosts["tightness_changed"]) {
        tightnessCostNew = newCosts["tightness"];
      }

      co2SensorCost = result.refItem.co2SensorCost;
      co2SensorCostNew = result.newItem.co2SensorCost;

      if (refCosts["co2Sensor_changed"]) {
        co2SensorCost = refCosts["co2Sensor"];
      }
      if (newCosts["co2Sensor_changed"]) {
        co2SensorCostNew = newCosts["co2Sensor"];
      }

      newWorkCost = this.buildingType === "current" ? newWorkCost : 0;
      workCost = this.buildingType === "current" ? workCost : 0;
      result.work =
        this.buildingType === "current"
          ? newWorkCost * surface
          : newWorkCost * surface;
      result.material =
        this.buildingType === "current"
          ? newMaterialCost + tightnessCostNew + co2SensorCostNew
          : newMaterialCost -
            materialCost +
            tightnessCostNew +
            co2SensorCostNew -
            co2SensorCost;

      result.service = result.material * 0.05;
      result.service = 0;
      result.cost =
        this.buildingType === "current"
          ? result.material + result.work + result.service
          : result.material + result.work;
      result.workPrice = this.isInReferenceState ? workCost : newWorkCost;
      result.materialPrice = this.isInReferenceState
        ? materialCost
        : newMaterialCost;
      result.costView = this.isInReferenceState
        ? materialCost + workCost * surface + co2SensorCost
        : newMaterialCost +
          newWorkCost * surface +
          tightnessCostNew +
          co2SensorCostNew;

      result.ventilation = {
        type: "ventilation",
        state: result.state,
        workPrice: result.workPrice,
        materialPrice: result.materialPrice,
        priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        workUnit: "hm.optimizer.thermoderm.cost.material.unit.zlm2",
      };

      result.tightness = {
        type: "tightness",
        show: this.isInReferenceState ? false : result.newItem.tightnessShow,
        state: result.state,
        materialPrice: this.isInReferenceState ? 0 : tightnessCostNew,
        priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      };

      result.co2Sensor = {
        type: "co2Sensor",
        show: this.isInReferenceState
          ? result.refItem.co2Sensor
          : result.newItem.co2Sensor,
        state: result.state,
        materialPrice: this.isInReferenceState
          ? co2SensorCost
          : co2SensorCostNew,
        priceUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
        workUnit: "hm.optimizer.thermoderm.cost.material.unit.zl",
      };
    }

    result.obsolescence = result.newItem.obsolescence;

    return result;
  };

  selectProperDevice = (
    ventData,
    devicePricesList,
    houseType,
    surface,
    personNumber
  ) => {
    let airVolume =
      EnergyCalcHelper.calcASHRAEStandardVentilationVolume(
        surface,
        personNumber
      ) * 3600;
    if (
      ventData.ventilationMethodObject.type === VentilationMethod.MECHANICAL &&
      ventData.ventilationMethodObject.recuperator
    ) {
      if (ventData.co2Sensor) {
        airVolume = 15.75 * personNumber;
      }
    }
    let costs = {
      price: 0,
      work: 0,
    };
    const isMulti = HouseTypeHelper.isMultiFamilyType(houseType);

    if (typeof devicePricesList.versions !== "undefined") {
      for (let i = 0; i < devicePricesList.versions.length; i++) {
        const version = devicePricesList.versions[i];
        costs.work = version.work;
        if (!isMulti) {
          if (
            version.air_volume > airVolume &&
            typeof version.single_house !== "undefined" &&
            version.single_house
          ) {
            costs.price = version.price;
            break;
          }
        } else {
          if (
            ventData.recuperatorLocation === RecuperatorLocation.INDIVIDUAL &&
            typeof version.multi_house !== "undefined" &&
            version.multi_house
          ) {
            costs.price = version.price;
            break;
          } else if (
            ventData.recuperatorLocation === RecuperatorLocation.AGGREGATED &&
            typeof version.multi_house_aggregated !== "undefined" &&
            version.multi_house_aggregated
          ) {
            costs.work = version.work;
            costs.price = version.price * surface;
            break;
          }
        }
      }
    }

    return costs;
  };
}
